<template>
  <div />
</template>
<script>
export default {
  mounted () {
    this.$router.replace({ path: '/' });
  },
};
</script>
